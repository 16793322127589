import {
  existsInCategory,
  filterNotHiddenItem,
  getTernaryValue,
  isCurrentPageRedirectFromRNA,
} from 'common/utilities/utils';
import { scroller } from 'react-scroll';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { ROUTE_URL } from 'common/constants/SharedConstants';
import { NUTRITION_ALLERGEN_CONSTANTS } from 'organisms/NutritionAllergen/Constants/NutritionAllergenConstant';
import {
  MENU_PLP_CONSTANT,
  AVERAGE_SERVING_SIZE,
  ENERGY,
  SODIUM,
  RNA_NUTRITION_ALLERGEN,
} from '../NutritionAllergenConstants/NutritionAllergenConstants';
import { checkIfCateringPage } from 'common/utilities/CateringPLPUtils';

const scrollMenuItem = ({
  categories,
  category_name,
  product_name,
  categoryToScroll,
  setParamValue,
  setCategoryStatus,
  isMobile,
  // applicableCoupons,
}) => {
  const offSetTopFirstCategory = -128;
  const offSetTopOtherCategory = -150;
  if (categoryToScroll) {
    const firstCategoryCheck = categories[0]?.url?.toLowerCase() === category_name?.toLowerCase();
    const offsettop = getTernaryValue(
      isMobile,
      getTernaryValue(firstCategoryCheck, offSetTopFirstCategory, offSetTopOtherCategory),
      -150,
    );
    scroller.scrollTo(categoryToScroll, {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: '',
      offset: offsettop,
    });
  } else {
    if (
      categories?.length &&
      window.location.pathname !== ROUTE_URL.NUTRITION_ALLERGEN &&
      window.location.pathname !== RNA_NUTRITION_ALLERGEN
    ) {
      let filteredCategories = filterNotHiddenItem(categories);
      filteredCategories.length &&
        scroller.scrollTo(filteredCategories?.[0]?.name?.trim(), {
          duration: 1500,
          delay: 100,
          smooth: true,
          containerId: '',
          offset: getTernaryValue(isMobile, offSetTopFirstCategory, offSetTopOtherCategory),
        });
    }
  }
};

export const getCategoryAndProduct = (
  categories,
  category_name,
  product_name,
  setProductId,
  setParamValue,
  setCategoryStatus,
  isMobile,
  // applicableCoupons,
) => {
  let categoryToScroll;
  //Filter out the category based on the url parameter received

  // To find the 1st level categories :Deeplink
  const filteredValue = categories?.filter(item => item?.url?.toLowerCase() === category_name?.toLowerCase());
  if (filteredValue && filteredValue?.length) {
    categoryToScroll = existsInCategory(filteredValue[0], categories);

    // Find the specific product if available
    const data_filter = filteredValue[0]?.products?.filter(function (element) {
      return element?.items?.some(function (subElement, idx) {
        return subElement?.url?.toLowerCase() === product_name?.toLowerCase();
      });
    });

    if (data_filter?.length) {
      const itemIndex = filteredValue[0]?.products?.findIndex(item => item?.id === data_filter[0]?.id);
      filteredValue[0]?.products?.splice(
        0, // new index,
        0, // no removal
        filteredValue[0]?.products?.splice(itemIndex, 1)[0], // detach the item and return it
      );
      setProductId(data_filter[0]?.items[0]?.id);
      const getElement = document.getElementById(`${data_filter[0]?.items[0]?.id}`);
      if (getElement) {
        getElement.style.border = '2px solid #2E4ABD';
      }
    }
    // Error messages to show :Deeplink
    if (data_filter.length === 0 && product_name) {
      setParamValue('item'); //Product
      setCategoryStatus(true);
    }
  }

  const menuParams = {
    categories,
    category_name,
    product_name,
    categoryToScroll,
    setParamValue,
    setCategoryStatus,
    isMobile,
    // applicableCoupons,
  };
  scrollMenuItem(menuParams);
};

export const shouldDisableButton = data => {
  return data?.isStoreClosed;
};

export const pathNameIncludesTrue = history => (isCurrentPageRedirectFromRNA(history?.location) ? '/true' : '');

export const menuNavigation = (categories, url, history, index) => {
  categories?.length &&
    scroller.scrollTo(categories[index]?.name?.trim(), {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: '',
      offset: -20,
    });
  const updatedPathName = pathNameIncludesTrue(history);
  history.push({
    pathname: `${ROUTE_URL.NUTRITION_ALLERGEN}/${url}${updatedPathName}`,
  });
};

export const setFirstCategoryActive = (
  e,
  index,
  catergoryName,
  url,
  filterNotHiddenItemDetails,
  categories,
  scrollerVal,
  setActiveMenuSelection,
  history,
) => {
  if (index === 0) {
    let filteredCategories = filterNotHiddenItemDetails(categories);
    filteredCategories?.length &&
      scrollerVal.scrollTo(filteredCategories[0]?.name?.trim(), {
        duration: 1500,
        delay: 100,
        smooth: true,
        containerId: '',
        offset: 50,
      });
  }
  setActiveMenuSelection(catergoryName);
  const updatedPathName = pathNameIncludesTrue(history);
  history.push({
    pathname: `${ROUTE_URL.NUTRITION_ALLERGEN}/${url}${updatedPathName}`,
  });
};

export const closeSearchFilterMenu = (filterList, mobileSearchFilterMenuRef, setHideTopRow) => {
  mobileSearchFilterMenuRef.current.style.width = '0'; // hide filter modal
  setHideTopRow(false);
};

export const changeMenulistOrder = menu => {
  let updatedMenuData = [];
  menu?.forEach(item => {
    if (item?.url === TRANSLATE_MAPPING_KEY.LTO_URL) {
      return updatedMenuData.unshift(item);
    } else {
      return updatedMenuData.push(item);
    }
  });
  return updatedMenuData;
};

export const getMenuData = pathname => {
  return getTernaryValue(
    checkIfCateringPage(pathname),
    { menuTitle: translateWithI18Next(TRANSLATE_MAPPING_KEY.CATERING_TEXT), showCateringflag: true },
    { menuTitle: translateWithI18Next(MENU_PLP_CONSTANT.NAV_TITLE), showCateringflag: false },
  );
};

export const showNutritionValue = (item, value) => {
  value = +value;
  if (
    item?.nutritionComponent === AVERAGE_SERVING_SIZE ||
    item?.nutritionComponent === ENERGY ||
    item?.nutritionComponent === SODIUM
  ) {
    return value?.toFixed();
  } else {
    return value?.toFixed(1);
  }
};
const getProductData = productinfo => {
  return productinfo?.map(data => {
    return {
      id: NUTRITION_ALLERGEN_CONSTANTS.SINGLE_ITEM_FIRST_PRODUCT_ID,
      url: NUTRITION_ALLERGEN_CONSTANTS.SINGLE_ITEM_URL,
      imageName: [{ value: data?.mainImage?.fields?.file?.url }],
      name: data?.itemName,
      longDescription: [
        {
          value: NUTRITION_ALLERGEN_CONSTANTS.LONG_DESCRIPTION,
        },
      ],
      dname: [
        {
          value: data?.itemName,
        },
      ],
      content: {
        caloricValue: data?.caloricValue,
      },
      items: [
        {
          content: {
            allergenInformation: data?.allergenInformation,
            nutritionalInformation: data?.nutritionalInformation,
            caloricValue: data?.caloricValue,
            hideAllergenInformation: data?.hideAllergenInformation,
            hideNutritionInformation: data?.hideNutritionInformation,
          },
          imageName: [{ value: data?.mainImage?.fields?.file?.url }],
          url: NUTRITION_ALLERGEN_CONSTANTS.SINGLE_ITEM_URL,
          dname: [
            {
              value: data?.itemName,
            },
          ],
        },
      ],
    };
  });
};
export const createSingleItemNodeList = data => {
  if (data) {
    const singleItemCategory = NUTRITION_ALLERGEN_CONSTANTS.SINGLE_ITEM;
    const categoryId = NUTRITION_ALLERGEN_CONSTANTS.SINGLE_ITEM_CATEGORY_ID;
    return {
      dname: [
        {
          value: singleItemCategory,
        },
      ],
      name: singleItemCategory,
      id: categoryId,
      isHidden: false,
      url: NUTRITION_ALLERGEN_CONSTANTS.SINGLE_ITEM_URL,
      products: getProductData(data),
    };
  }
};

export const getNASingleItemData = naSingleItemContent => {
  const { naSingleItemResp } = naSingleItemContent || {};
  const { items } = naSingleItemResp || {};
  let naSingleItemDetails = [];
  items?.forEach(item => {
    naSingleItemDetails?.push(item?.fields);
  });
  return naSingleItemDetails;
};

export const modifyProdsData = (singleItem, data) => {
  if (singleItem) {
    const { id } = singleItem;
    let { products: menuProducts } = data;
    const { products: singleItemProducts } = singleItem;
    if (data?.categories?.filter(value => value?.id === id).length === 0) {
      data?.categories?.push(singleItem);
    }
    if (data?.menu?.filter(value => value?.id === id).length === 0) {
      data?.menu?.push(singleItem);
    }

    let productsToBeAdded = singleItemProducts?.filter(function (obj) {
      return !menuProducts.some(function (obj2) {
        return obj?.id === obj2?.id;
      });
    });
    if (productsToBeAdded) {
      const updatedMenuProducts = menuProducts?.concat(productsToBeAdded);
      menuProducts = [...updatedMenuProducts];
    }
    data = { ...data, products: menuProducts };
  }
  return data;
};
